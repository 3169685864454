@import './Colors';

.default-layout {
  nav {
    position: absolute;
    z-index: 1000;
    top: 10px;
    right: 10px;

    a, a:visited, a:hover, a:active {
      color: #fff !important;
    }
  }


  header {
    background-color: $darkGreen;
    color: #fff;
    text-align: center;
    padding: 40px 20px;

    h1 {
      margin: 0;

    }

    a, a:visited, a:hover, a:active {
      color: #fff !important;
    }

    h2 {
      color: #fff;
      border: 0;
      margin: 0;
      text-align: center;
      max-width: unset;
    }
  }

  h2 {
    color: $darkGreen;
    margin: 0 auto 40px;
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 1px solid $green;
    max-width: 80%;
  }

  section {
    margin: 40px auto;
    max-width: 768px;
    padding: 0 20px;

    &.default-layout--colored-section {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  &--light-background {
    background: #f9f9f9;
  }
}

.default-layout-content {
  max-width: 768px;
  margin: 0 auto;
  position: relative;
}