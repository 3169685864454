nav {
    display: flex;
    flex-direction: row;
}

nav a {
    display: inline-block;
    padding: 5px 10px;
    text-decoration: none;
    white-space: nowrap;
    color: #fff;
}