@import 'Colors';

.form {
  max-width: 500px;
  margin: 0 auto;
}

.form-field {
  margin: 10px 0;

  &.compact {
    max-width: 200px;
  }
}

.form-buttons {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}

.form-button-spacing {
  display: inline-block;
  width: 20px;
}

.StripeElement {
  border: 1px solid #a6a6a6;
  padding: 8px 12px;
}

.ms-Button--primary {
  background-color: $green !important;
}

.ms-Checkbox.is-checked {

  .ms-Checkbox-checkbox {
    background: $darkGreen !important;
  }
}

.ms-Checkbox-checkbox {
  border-color: $darkGreen !important;
}