@import 'Colors';

.admin-layout {
  margin-bottom: 40px;

  nav {
    background-color: $darkGreen;
    color: #fff;
    align-items: center;
    justify-content: center;

    a, a:visited, a:hover, a:active {
      color: #fff !important;
    }
  }

  header {
    background-color: $darkGreen;
    color: #fff;
    text-align: center;
    padding: 40px 20px;

    h1 {
      margin: 0;
    }

    a, a:visited, a:hover, a:active {
      color: #fff !important;
    }
  }

  h2 {
    color: $darkGreen;
    margin: 0 auto 40px;
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 1px solid $green;
    max-width: 80%;
  }

  hr {
    height: 1px;
    margin: 40px auto;
    background-color: $green;
    border: 0;
    max-width: 80%;
  }

  section {
    margin: 40px auto;
    max-width: 768px;
    padding: 0 20px;

    &.default-layout--colored-section {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  &--light-background {
    background: #f9f9f9;
  }
}

.admin-layout-content {
  max-width: 768px;
  margin: 0 auto;
  position: relative;
}


.ms-Dialog-header {
  background: $darkGreen !important;
}

.table {
  border-collapse: collapse;
  width: 100%;

  tr {
    border-bottom: 1px solid #ccc;
  }

  tfoot tr {
    border-bottom: 0;
  }

  th {
    text-align: left;
    font-size: smaller;
    font-weight: normal;
    color: #666;
    padding: 5px 10px;
  }

  td {
    padding: 5px 10px;
  }

  &--actions {
    text-align: right;
  }

  &--subtitle {
    color: #666;
    font-size: smaller;
  }
}