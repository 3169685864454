@import 'Colors';

.home {
  nav {
    position: absolute;
    z-index: 1000;
    top: 10px;
    right: 10px;

    a, a:visited, a:hover, a:active {
      color: #fff !important;
    }
  }

  h1 {
    margin-bottom: 20px;
  }

  h2 {
    margin: 0 auto 40px;
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 1px solid $green;
    max-width: 80%;
  }

  section {
    margin: 40px auto;
    max-width: 768px;
    padding: 0 20px;

    &.home--colored-section {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  &--hero {
    background: $darkGreen no-repeat center center;
    background-size: cover;
    color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;

    h1 {
      text-align: center;
    }

    h2 {
      border: 0;
      text-align: left;
      max-width: unset;
      padding: 0;
    }

    &--content {
      padding: 50px 10px 0 10px;
    }

    &--text-wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-around;

      @media (max-width: 450px) {
        flex-direction: column-reverse;
        align-items: stretch;
      }
    }

    &--text {
      flex: 0 0 50%;
      padding: 20px 20px;
      text-align: left;
      align-self: center;

      p {
        margin: 1em 0 2em;
      }

      a.button, a.button:visited, a.button:active, a.button:hover {
        background-color: #fff;
        color: $darkGreen !important;
      }
    }

    &--image {
      flex: 0 0 50%;
      min-height: 350px;
      position: relative;
      overflow: hidden;

      @media (max-width: 450px) {
        flex: 1 1 auto;
        max-height: 300px;
        text-align: center;
      }

      &--image {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 450px;

        @media (max-width: 450px) {
          position: relative;
          top: unset;
          right: unset;
        }
      }
    }
  }

  &--reasons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;

    > div {
      flex: 1 0 40%;
      margin: 0 5%;
    }

    @media (max-width: 450px) {
      flex-direction: column;

      > div {
        margin: 0 0 20px;
      }
    }
  }

  &--why {
    //background-color: $lightGreen;
    //padding: 30px 20px;
  }

  &--testimonials {
    //overflow-x: scroll;
    //-webkit-overflow-scrolling: touch;

    &--wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: flex-start;
    }

    &--item {
      flex: 1 0 200px;
      margin: 1%;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
      background: $green;
      border-radius: 10px;
      color: #fff;

      blockquote {
        margin: 30px 20px 20px;
        font-style: italic;
      }

      blockquote:before {
        content: '';
        background: url(../images/quotationmark.svg) no-repeat center center;
        background-size: contain;
        display: block;
        height: 40px;
        margin-bottom: 20px;
      }
    }
  }

  &--pricing {
    background: #f9f9f9;

    &--plans {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;

      @media (max-width: 450px) {
        flex-direction: column;

      }
    }
  }
}
