.home nav {
  position: absolute;
  z-index: 1000;
  top: 10px;
  right: 10px; }
  .home nav a, .home nav a:visited, .home nav a:hover, .home nav a:active {
    color: #fff !important; }

.home h1 {
  margin-bottom: 20px; }

.home h2 {
  margin: 0 auto 40px;
  text-align: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #4dd76b;
  max-width: 80%; }

.home section {
  margin: 40px auto;
  max-width: 768px;
  padding: 0 20px; }
  .home section.home--colored-section {
    padding-top: 40px;
    padding-bottom: 40px; }

.home--hero {
  background: #499b4e no-repeat center center;
  background-size: cover;
  color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px; }
  .home--hero h1 {
    text-align: center; }
  .home--hero h2 {
    border: 0;
    text-align: left;
    max-width: unset;
    padding: 0; }
  .home--hero--content {
    padding: 50px 10px 0 10px; }
  .home--hero--text-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around; }
    @media (max-width: 450px) {
      .home--hero--text-wrapper {
        flex-direction: column-reverse;
        align-items: stretch; } }
  .home--hero--text {
    flex: 0 0 50%;
    padding: 20px 20px;
    text-align: left;
    align-self: center; }
    .home--hero--text p {
      margin: 1em 0 2em; }
    .home--hero--text a.button, .home--hero--text a.button:visited, .home--hero--text a.button:active, .home--hero--text a.button:hover {
      background-color: #fff;
      color: #499b4e !important; }
  .home--hero--image {
    flex: 0 0 50%;
    min-height: 350px;
    position: relative;
    overflow: hidden; }
    @media (max-width: 450px) {
      .home--hero--image {
        flex: 1 1 auto;
        max-height: 300px;
        text-align: center; } }
    .home--hero--image--image {
      position: absolute;
      top: 20px;
      right: 20px;
      height: 450px; }
      @media (max-width: 450px) {
        .home--hero--image--image {
          position: relative;
          top: unset;
          right: unset; } }

.home--reasons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly; }
  .home--reasons > div {
    flex: 1 0 40%;
    margin: 0 5%; }
  @media (max-width: 450px) {
    .home--reasons {
      flex-direction: column; }
      .home--reasons > div {
        margin: 0 0 20px; } }

.home--testimonials--wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start; }

.home--testimonials--item {
  flex: 1 0 200px;
  margin: 1%;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  background: #4dd76b;
  border-radius: 10px;
  color: #fff; }
  .home--testimonials--item blockquote {
    margin: 30px 20px 20px;
    font-style: italic; }
  .home--testimonials--item blockquote:before {
    content: '';
    background: url(/static/media/quotationmark.300b0db6.svg) no-repeat center center;
    background-size: contain;
    display: block;
    height: 40px;
    margin-bottom: 20px; }

.home--pricing {
  background: #f9f9f9; }
  .home--pricing--plans {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between; }
    @media (max-width: 450px) {
      .home--pricing--plans {
        flex-direction: column; } }

.default-layout nav {
  position: absolute;
  z-index: 1000;
  top: 10px;
  right: 10px; }
  .default-layout nav a, .default-layout nav a:visited, .default-layout nav a:hover, .default-layout nav a:active {
    color: #fff !important; }

.default-layout header {
  background-color: #499b4e;
  color: #fff;
  text-align: center;
  padding: 40px 20px; }
  .default-layout header h1 {
    margin: 0; }
  .default-layout header a, .default-layout header a:visited, .default-layout header a:hover, .default-layout header a:active {
    color: #fff !important; }
  .default-layout header h2 {
    color: #fff;
    border: 0;
    margin: 0;
    text-align: center;
    max-width: unset; }

.default-layout h2 {
  color: #499b4e;
  margin: 0 auto 40px;
  text-align: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #4dd76b;
  max-width: 80%; }

.default-layout section {
  margin: 40px auto;
  max-width: 768px;
  padding: 0 20px; }
  .default-layout section.default-layout--colored-section {
    padding-top: 40px;
    padding-bottom: 40px; }

.default-layout--light-background {
  background: #f9f9f9; }

.default-layout-content {
  max-width: 768px;
  margin: 0 auto;
  position: relative; }

.admin-layout {
  margin-bottom: 40px; }
  .admin-layout nav {
    background-color: #499b4e;
    color: #fff;
    align-items: center;
    justify-content: center; }
    .admin-layout nav a, .admin-layout nav a:visited, .admin-layout nav a:hover, .admin-layout nav a:active {
      color: #fff !important; }
  .admin-layout header {
    background-color: #499b4e;
    color: #fff;
    text-align: center;
    padding: 40px 20px; }
    .admin-layout header h1 {
      margin: 0; }
    .admin-layout header a, .admin-layout header a:visited, .admin-layout header a:hover, .admin-layout header a:active {
      color: #fff !important; }
  .admin-layout h2 {
    color: #499b4e;
    margin: 0 auto 40px;
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 1px solid #4dd76b;
    max-width: 80%; }
  .admin-layout hr {
    height: 1px;
    margin: 40px auto;
    background-color: #4dd76b;
    border: 0;
    max-width: 80%; }
  .admin-layout section {
    margin: 40px auto;
    max-width: 768px;
    padding: 0 20px; }
    .admin-layout section.default-layout--colored-section {
      padding-top: 40px;
      padding-bottom: 40px; }
  .admin-layout--light-background {
    background: #f9f9f9; }

.admin-layout-content {
  max-width: 768px;
  margin: 0 auto;
  position: relative; }

.ms-Dialog-header {
  background: #499b4e !important; }

.table {
  border-collapse: collapse;
  width: 100%; }
  .table tr {
    border-bottom: 1px solid #ccc; }
  .table tfoot tr {
    border-bottom: 0; }
  .table th {
    text-align: left;
    font-size: smaller;
    font-weight: normal;
    color: #666;
    padding: 5px 10px; }
  .table td {
    padding: 5px 10px; }
  .table--actions {
    text-align: right; }
  .table--subtitle {
    color: #666;
    font-size: smaller; }

.form {
  max-width: 500px;
  margin: 0 auto; }

.form-field {
  margin: 10px 0; }
  .form-field.compact {
    max-width: 200px; }

.form-buttons {
  display: flex;
  align-items: baseline;
  justify-content: flex-start; }

.form-button-spacing {
  display: inline-block;
  width: 20px; }

.StripeElement {
  border: 1px solid #a6a6a6;
  padding: 8px 12px; }

.ms-Button--primary {
  background-color: #4dd76b !important; }

.ms-Checkbox.is-checked .ms-Checkbox-checkbox {
  background: #499b4e !important; }

.ms-Checkbox-checkbox {
  border-color: #499b4e !important; }

nav {
    display: flex;
    flex-direction: row;
}

nav a {
    display: inline-block;
    padding: 5px 10px;
    text-decoration: none;
    white-space: nowrap;
    color: #fff;
}
* {
  box-sizing: border-box; }

body, html {
  margin: 0;
  padding: 0; }

a, a:visited, a:active, a:hover, .ms-Link, .ms-Link:visited, .ms-Link:active, .ms-Link:hover {
  color: #4dd76b !important;
  text-decoration: none; }

.button, a.button {
  background-color: #4dd76b;
  color: #fff !important;
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25); }

.button:hover, .button:visited, .button:active {
  color: #ffffff; }

