@import 'Colors';

* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
}

a, a:visited, a:active, a:hover, .ms-Link, .ms-Link:visited, .ms-Link:active, .ms-Link:hover {
  color: $green !important;
  text-decoration: none;
}

.button, a.button {
  background-color: $green;
  color: #fff !important;
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
}

.button:hover, .button:visited, .button:active {
  color: #ffffff;
}
